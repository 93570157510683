<template>
    <b-overlay :show="showLoader">
        <b-card class="footer-pages">
            <b-form-group class="mt-1" label="Naslov strani">
                <b-form-input :disabled="!$hasPermissions($permissions.EditPages)" class="mt-1" v-model="title"/>
            </b-form-group>

            <b-form-group class="mt-1" label="Potek nakupa">
                <TextAreaEditor :disabled="!$hasPermissions($permissions.EditPages)" class="mt-1" v-model="description_1"/>
            </b-form-group>

            <b-button @click="updateDescription" v-if="$hasPermissions($permissions.EditPages)">Posodobi</b-button>
        </b-card>
    </b-overlay>
</template>

<script>
    import { BCard, BFormGroup, BButton, BFormInput, BOverlay } from 'bootstrap-vue'
    import TextAreaEditor from '@/views/Components/TextAreaEditor'

    export default {
        components:{
            BCard,
            BFormGroup,
            TextAreaEditor,
            BButton,
            BFormInput,
            BOverlay
        },
        data() {
            return {
                showLoader: false,
                description_1: '',
                title: ''
            }
        },
        methods:{
            async loadData() {
                this.showLoader = true
                try {
                    const res = await this.$http.get('/api/management/v1/pages/purchase_steps')
                    if (res && res.data) {
                        this.description_1 = res.data.description_1
                        this.title = res.data.title
                    }
                } catch (err) {
                    this.$printError('Prislo je do napake pri nalaganju podatkov')
                } finally {
                    this.showLoader = false
                }
            },
            async updateDescription() {
                this.showLoader = true
                try {
                    const payload = {
                        description_1: this.description_1,
                        title: this.title
                    }
                    await this.$http.post('/api/management/v1/pages/purchase_steps', payload)
                    this.$printSuccess('Uspesno posodobljeno')

                } catch (err) {
                    this.$printError('Prislo je do napake pri posodobitvi podatkov')
                } finally {
                    this.showLoader = false
                }
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Encode+Sans:wght@400;500;700;800&display=swap');

    .footer-pages li{
        font-family: 'Encode Sans', sans-serif !important;
    }
    .footer-pages p {
        font-family: 'Encode Sans', sans-serif !important;
        margin-bottom: 0px !important;
        margin-top: 0px !important;
    }

    .footer-pages a {
        font-family: 'Encode Sans', sans-serif !important;
        color: #72A5D8;
        font-weight: 700;
    }

    .footer-pages h4 {
        font-family: 'Encode Sans', sans-serif !important;
        font-size: 17px;
        padding-bottom: 5px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.7);
    }

    .footer-pages h1 {
        font-family: 'Encode Sans', sans-serif !important;
        font-size: 1.3rem;        
        font-weight: bold;
    }

    .footer-pages h2 {
        font-family: 'Encode Sans', sans-serif !important;
        font-size: 17px !important;        
        font-weight: normal;
        padding-bottom: 15px;
        margin-bottom: 35px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.7);
    }

    .footer-pages iframe {
        height: 330px !important;
        width: 600px !important;
        margin-left: auto;
        margin-right: auto;
    }
    @media only screen and (max-width: 992px) {
        .footer-pages iframe {
            height: 200px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
         }
    }
</style>